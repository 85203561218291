import React from "react"
import styled from "styled-components"
import Links from "../constants/sociallinks"
import Part from "react-particles-js"
import { Link } from "gatsby"

const Hero = () => {
  return (
    <HeroHeader>
      <Particles
  params={{
    particles: {
      number: {
        value: 800, // A huge number of particles for an intense fire-like effect
        density: {
          enable: true,
          value_area: 800 // Adjusted for optimal spacing
        }
      },
      color: {
        value: ["#FF4500", "#FF6347", "#FFA07A", "#FFD700"] // Warm fire colors
      },
      shape: {
        type: ["circle", "edge"], // Mixing shapes for a more natural flame look
      },
      opacity: {
        value: 0.6,
        random: true, // Varied opacity for a flickering flame effect
        anim: {
          enable: false,
        }
      },
      size: {
        value: 3, // Slightly larger particles for visibility
        random: true,
        anim: {
          enable: false,
        }
      },
      line_linked: {
        enable: false, // Disabled to maintain the focus on individual 'flames'
      },
      move: {
        enable: true,
        speed: 1, // Moderate speed to simulate rising flames
        direction: "top", // Upward movement to mimic rising fire
        random: false,
        straight: false,
        out_mode: "out",
        bounce: false,
        attract: {
          enable: false, // Disabling attraction for a more chaotic, flame-like movement
        }
      }
    },
    interactivity: {
      detect_on: "canvas",
      events: {
        onhover: {
          enable: true,
          mode: "repulse"
        },
        onclick: {
          enable: true,
          mode: "push" // Adding more particles on click for interaction
        },
        resize: true
      },
      modes: {
        repulse: {
          distance: 70,
          duration: 0.4
        },
        push: {
          particles_nb: 30 // More particles per click for a burst of flames
        },
      }
    },
    retina_detect: true
  }}
/>







      <HeroCenter>
        <HeroInfo>
          <div>
            <Underline></Underline>
            <Name>Juan Reyes-Ortiz</Name>
            <H4>Software Engineer</H4>
            <Link to="/about">
              <HeroButton>About Me</HeroButton>
            </Link>
            <Links isSideBar={false} color="white" />
          </div>
        </HeroInfo>
      </HeroCenter>
    </HeroHeader>
  )
}

const Name = styled.h1`
  color: ${({ theme }) => theme.colorHeader};
  @media screen and (max-width: 768px) {
    font-size: 2.3rem;
  }
`
const H4 = styled.h4`
  color: ${({ theme }) => theme.colorHeader};
  @media screen and (max-width: 768px) {
    font-size: 0.9rem;
  }
`
const Underline = styled.div`
  margin-bottom: -1rem;
  margin-left: 0;
  margin-right: auto;
  width: 5rem;
  height: 0.25rem;
  background: ${({ theme }) => theme.colorPrimary5};
`
//Particles component positioning
const Particles = styled(Part)`
  position: absolute;
  margin-top: -5rem;
  padding-top: 5rem;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  height: 70vh;

  @media screen and (max-width: 992px) {
    height: 65vh;
    opacity: 1;
  }
`
const HeroHeader = styled.header`
  background: ${({ theme }) => theme.background};
  height: 80vh;

  @media screen and (min-width: 992px) {
  }
  @media screen and (min-width: 1170px) {
    font-size: 1rem;
  }
`
const HeroCenter = styled.div`
  height: 100%;
  display: grid;
  align-items: center;
  width: 90vw;
  margin: 0 auto;
  max-width: 1170px;

  @media screen and (min-width: 992px) {
    width: 95vw;
  }
`
const HeroInfo = styled.article`
  position: relative;
  @media screen and (min-width: 992px) {
    grid-row: 1/1;
    grid-column: 1 / span 8;
  }
  @media screen and (min-width: 1170px) {
    grid-column: 1 / span 8;
  }
`
const HeroButton = styled.button`
  margin-top: 1.25rem;
`

export default Hero
